<template>
  <div class="user-list-container">
    <!--! Header row -->
    <b-row>
      <b-col cols="">
        <div class="count-account">
          <p class="count__account-text">全ユーザー数<strong class="ml4 count__account-num">{{pagination.total}}</strong></p>
        </div>
      </b-col>
      <!-- Search input-->
      <b-col>
        <div class="header-menu-bar col-12">
          <b-button-toolbar aria-label="Toolbar with button groups and input groups">
            <b-input-group class="search-input" size="md">
              <b-form-input @keyup.enter="handleSearch" :formatter="limitSearch" v-model="querySearch.search_key" placeholder="ユーザー検索"></b-form-input>
              <b-button @click="handleSearch" variant="outline-primary" class="btn btn-primary btn-search">
                <feather-icon icon="SearchIcon" size="14"> </feather-icon>
              </b-button>
            </b-input-group>
          </b-button-toolbar>
        </div>
      </b-col>
    </b-row>
    <!--! Table row -->
    <b-row>
      <!-- <b-col cols="12" class="m-2">
          <paginate :total-record="totalRows" :current-page.sync="querySearch.page" :page-size-default.sync="querySearch.size" />
        </b-col> -->
      <b-col cols="12">
        <div class="list-size mb-1" style="width: 160px">
          <v-select
            v-model="querySearch.size"
            :clearable="false"
            :options="options"
            @input="handleSearch"
          />
        </div>
        <b-table
          hover
          responsive
          :items="list_user"
          :fields="header"
          :sort-by.sync="sortBy"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          thead-tr-class="header-split"
          tbody-tr-class="table-white-line"
        >
          <template v-for="item in header" v-slot:[`head(`+item.key+`)`]="data">
            <div
              :style="'width:' + data.field.width"
              class="header-table"
              :key="item.key"
            >
              <span class="text-center">{{ data.label }}</span>
            </div>
          </template>
          <template #cell(login_id)="data">
            <div class="user-max-width-col">{{ data.item.login_id }}</div>
          </template>
          <template #cell(username)="data">
            <div class="user-max-width-col">
              <b-avatar :src="data.item.avatar ? data.item.avatar : ''" size="2rem"></b-avatar>
              <span>{{ data.item.username }}</span>
            </div>
          </template>
          <template #cell(age)="data">
            {{ data.item.age ? data.item.age +'歳' : '' }}
            <b-avatar variant="success" class="ml4" size="18" v-if="data.item.age_status === 1">
              <feather-icon size="14" icon="CheckIcon"></feather-icon>
            </b-avatar>
          </template>
          <template #cell(action)="data">
            <span class="relative"
                  :class="[data.item.unread_message ? 'unread-msg' : '']"
            >
              <feather-icon
                icon="MessageSquareIcon"
                size="20"
                style="margin-right: 15px"
                @click="matchUserVsInquiry(data.item.id)"
              ></feather-icon>
            </span>
            <feather-icon
              icon="MoreVerticalIcon"
              size="20"
              @click="getUserSelect(data.item)"
            ></feather-icon>
          </template>
          <template #empty>
            <p class="text-center">データが見つかりません。</p>
          </template>
        </b-table>
        <div class="pagination float-right mb-3">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            @change="changePage">
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <!--! Dialog section -->
    <!--* Dialog detail user -->
    <Dialog
      :dialog="dialogDetailUser"
      :showCancelButton="false"
      :showOkButton="false"
      :hideHeader="true"
      :centered="true"
      @closeDialog="dialogDetailUser = false"
    >
      <template #content>
        <div class="modal-container-user">
          <div class="modal-header-user">
            <div class="user-info">
              <b-avatar
                :src="userSelect.avatar"
                size="5rem"
              ></b-avatar>
              <p>{{userSelect.username}}</p>
            </div>
            <b-button
              variant="danger"
              @click="
                () => {
                  dialogDeleteUser = true;
                  dialogDetailUser = false;
                }
              "
              >強制退会</b-button
            >
          </div>
          <div class="separate-line"></div>
          <div class="modal-body-user">
            <p class="user-detail-ctn"><span class="user-detail">メールアドレス</span>{{ userSelect.login_id }}</p>
            <p class="user-detail-ctn"><span class="user-detail">性別</span>{{ userSelect.sex }}</p>
            <p class="user-detail-ctn">
              <span class="user-detail">年齢</span>{{ userSelect.age }}歳
              <span v-if="userSelect.age_status === 1">
                <b-avatar variant="success" class="ml4" size="18">
                  <feather-icon size="14" icon="CheckIcon"></feather-icon>
                </b-avatar>
                （年齢確認日：2022/10/23）
              </span>
            </p>
            <p class="user-detail-ctn">
              <span class="user-detail">会員区分</span>{{ userSelect.user_status }}
            </p>
            <b-button
              class="close-modal-btn"
              variant="outline-secondary"
              @click="dialogDetailUser = false"
              >閉じる</b-button
            >
          </div>
        </div>
      </template>
    </Dialog>

    <!--* Dialog delete user -->
    <Dialog
      :dialog="dialogDeleteUser"
      :showCancelButton="false"
      :showOkButton="false"
      :hideHeader="true"
      :centered="true"
      :size="'md'"
      @closeDialog="dialogDeleteUser = false"
    >
      <template #content v-if="userSelect">
        <div class="modal-container-delete-user">
          <h2>強制退会</h2>
          <h5>強制退会処理を行います。よろしいですか？</h5>
          <div class="btn-group-footer">
            <b-button variant="outline-secondary" class="mr-10" @click="dialogDeleteUser = false"> キャンセル </b-button>
            <b-button variant="danger" @click="destroyUserSelect(userSelect.id)" >強制退会</b-button>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {
  BDropdownDivider,
  BDropdownItem,
  BDropdown,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  VBTooltip,
  BTooltip,
  BFormCheckbox,
  BPagination,
} from "bootstrap-vue";
import store from "@/store";
import { mapState } from 'vuex';
import { apiService } from "@/services/api.service";
import Dialog from "@core/components/dialog/Dialog";
import FloatInput from "@/@core/components/float-input/FloatInput.vue";
import {STATUS, USER_SEX_STATUS, USER_AGE_STATUS} from "@/constant/constant";
import vSelect from "vue-select";
import router from "@/router";
import "vue-select/dist/vue-select.css";
export default {
  name: 'UserList',
  props: [],
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    Dialog,
    BTooltip,
    BFormCheckbox,
    FloatInput,
    BPagination,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      sortBy: "",
      deleteMessage: "",
      filter: null,
      filterOn: [],
      userSelect: {
        age : "",
        age_status : USER_AGE_STATUS,
        payment_at : "",
        payment_status : "",
        sex : USER_SEX_STATUS,
        user_status: "",
        status : "",
        username : "",
      },
      header: [
        { label: "メールアドレス", key: "login_id", value: "", width: "130px", maxwidth: "300px" },
        { label: "ユーザー名", key: "username", value: "", width: "150px", maxwidth: "300px"  },
        { label: "性別", key: "sex", value: "", width: "100px" },
        { label: "年齢", key: "age", value: "", width: "100px" },
        { label: "会員区分", key: "user_status", value: "", width: "100px" }, //role name
        { label: "最新決済日", key: "payment_at", value: "", width: "100px" }, // payment_at
        { label: "決済状況", key: "payment_status", value: "", width: "100px" },
        { label: "", key: "action", value: "", width: "100px" },
      ],
      list_user: [],
      dialogDetailUser: false,
      dialogDeleteUser: false,
      pagination: {
        currentPage: 1,
        total: 0,
        perPage: 25,
      },
      querySearch: {
        page: "",
        size: 25,
        search_key: "",
      },
      dateRange: "",
      options: [10, 25, 50],
    };
  },
  mounted() {
    this.getListUser();
  },
  computed: {
    queryRoute() {
      return this.$route.query;
    },
    ...mapState({
      listCountUnreadMsg : state => state.totalCountUnreadMsg.totalNumberUnreadOfMessages
    }),
  },
  watch: {
    queryRoute() {
      this.getListUser();
    },
  },
  methods: {
    /* (handleSearch): Handle search function ------------------------------------------------------------------------- */
    handleSearch() {
      this.$router
        .push({
          name: this.$route.name,
          query: {
            ...this.queryRoute,
            ...this.querySearch,
            ...{ page: "1" },
          },
        })
        .catch(() => {});
      this.getListUser();
    },
    /* (changePage): Handle change pagination ------------------------------------------------------------------------- */
    changePage(e) {
      this.$router
        .push({
          name: this.$route.name,
          query: { ...this.queryRoute, ...{ page: e } },
        })
        .catch(() => {});
    },

    changeDialogUpdate(){
      this.dialogDetailUser = ! this.dialogDetailUser
    },
    // Get list User
    async getListUser() {
      this.startLoading();
      try {
        const res = await apiService.get(`/manage-user?${this.serialize(this.queryRoute)}`);
        if (res.data && res.data.code === STATUS.SUCCESS) {
          this.list_user = res.data.data.user;
          if (this.listCountUnreadMsg && this.listCountUnreadMsg.length){
            this.list_user.map(item =>{
              item.unread_message = this.listCountUnreadMsg.find(c=>item.id === c.user_id[0])
            })
          }
          this.pagination.total = res.data.pagination.total;
          this.pagination.perPage = res.data.pagination.per_page;
          this.pagination.currentPage = res.data.pagination.current_page;
        } else if (res.data.code === STATUS.NO_CONTENT) {
          this.list_user = [];
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        this.notifyError(error.data.message);
      }finally {
        this.endLoading();
      }
    },
    async getUserSelect(user){
      this.userSelect = Object.assign({},user);
      if (this.userSelect){
        this.changeDialogUpdate();
      }
    },
    // Delete User
    async destroyUserSelect(id){
      this.dialogDeleteUser = false
      this.startLoading();
      try {
        let res = await apiService.delete(`/manage-user/${id}`);
        if (res.data.code === STATUS.SUCCESS){
          this.dialogDeleteUser = false
          this.getListUser();
          this.notifySuccess(STATUS.MESSENGER_DELETE_SUCCESS)
        }
      }catch (e) {
        this.notifyError(e.data.message)
      }finally {
        this.endLoading();
      }
    },
    limitSearch(e) {
      return String(e).substring(0, 20);
    },
    pageChangeHandler(page){
      this.getListUser(page).catch(err => {
        console.error(err.response.message);
      })
    },
    // match user vs chat inquiry
    matchUserVsInquiry(user_id){
      store.dispatch('totalCountUnreadMsg/matchUserVsInquiryChat',user_id);
      router.push({ name: 'inquiry-list' })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.user-max-width-col {
  max-width: 350px;
  display: flex;
  word-break: keep-all;
  align-items: center;

  span {
    margin-left: 4px;
  }
}
.row-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;

  .search-input {
    width: 300px;
    position: absolute;
    right: 28px;
    top: 30px;
  }
}
.count-account{
  font-size: 16px;
  .count__account-text{
      color: #6F767E;
  }
  .count__account-num{
      color:#000000;
  }
}

.btn-search{
  margin-left: -7px;
}
.header-menu-bar{
  display: inline-flex;
  position: relative;
  bottom: 55px;
  right: 0;
  padding: 0;
  justify-content: flex-end;
}
.ml4 {
  margin-left: 4px;
}
.modal-container-user {
  padding: 0 20px;
  .modal-header-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0 15px;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  .modal-body-user {
    margin: 20px 0;
    display: flex;
    flex-direction: column;

    .user-detail-ctn {
      font-size: 16px;
      line-height: 22px;
      .user-detail {
        display: inline-block;
        width: 185px;
      }
    }

    .close-modal-btn {
      width: 90px;
      align-self: flex-end;
    }
  }
}

.modal-container-delete-user {
  padding: 20px;
  display: flex;
  flex-direction: column;

  h5 {
    margin: 20px 0;
  }

  .btn-group-footer {
    align-self: flex-end;

    .mr-10 {
      margin-right: 10px;
    }
  }
}
.unread-msg:after{
  position: absolute;
  content : '';
  background: red;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  top: 5%;
  right: 45%;
}
</style>
